import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../_pages/Home';
import Mapa from '../_pages/Mapa';

export default function Routes() {
  return (
    <Switch>
      {/* authenticated routes */}
      <Route path="/" exact component={Home} />

      {/* unauthenticated routes */}
      <Route path="/home" component={Home} />
      <Route path="/mapa" component={Mapa} />
    </Switch>
  );
}
