/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import {
  Button,
  Col,
  Layout,
  message,
  Row,
  Slider,
  Steps,
  Checkbox,
  Result,
  Select,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import CurrencyFormat from 'react-currency-format';
import * as Yup from 'yup';
import { SmileOutlined } from '@ant-design/icons';
import api from '../../services/api';
import './styles.css';

const { Option } = Select;

const sessionData = JSON.parse(sessionStorage.getItem('__bvsimulator')) || null;

const schema = Yup.object().shape({
  nomeCompleto: Yup.string().required('Informação obrigatória'),
  email: Yup.string()
    .email('Favor informar um email válido.')
    .required('Informação obrigatória.'),
  celular: Yup.string().required('Informação obrigatória'),
  corretor: Yup.string(),
});

const { Content } = Layout;
const { Step } = Steps;

const valoresDefault = {
  divida: 220000,
  entrada: 40000,
  entradaMaxima: 80000,
  saldo: 180000,
  juros: 1.15,
  jurosParcelado: 1.15,
  parcelas: 120,
  valorParcela: 3081.32,
  parcelaEntrada: 1,
};

const toNumber = value => {
  const v = Number.isNaN(value)
    ? value.replace('.', '').replace(',', '.')
    : value;
  return parseFloat(v);
};

export default function Simulador({ lote, tipo, setTipo, setLote }) {
  const [current, setCurrent] = useState(0);
  const [calculo, setCalculo] = useState({ ...valoresDefault });

  useEffect(() => {
    valoresDefault.divida = lote.price * lote.m2;
    valoresDefault.entrada = 40000;
    valoresDefault.entradaMaxima = valoresDefault.divida;
    valoresDefault.saldo = valoresDefault.divida - valoresDefault.entrada;
    valoresDefault.parcelas = 120;
    valoresDefault.valorParcela = 0; // lote.price === 1000 ? 3235.39 : 2773.19;

    valoresDefault.lote = lote;
    valoresDefault.tipo = tipo;
    setCalculo({ ...valoresDefault });
  }, [lote, tipo]);

  const handleSubmit = data => {
    setCurrent(current + 1);
    sessionStorage.setItem('__bvsimulator', JSON.stringify(data));
  };

  const getIdentification = () => {
    const data = sessionStorage.getItem('__bvsimulator');
    return JSON.parse(data);
  };

  const handleChangeEntrada = value => {
    value.floatValue = value.floatValue || 0;
    if (value.floatValue >= calculo.divida) {
      setCalculo(old => ({
        ...old,
        parcelas: 0,
        entrada: toNumber(old.divida) || 0,
        valorParcela: 0,
        saldo: 0,
        parcelaEntrada: 1,
      }));
    } else if (calculo.divida - value.floatValue >= calculo.valorParcela) {
      setCalculo({
        ...calculo,
        parcelas:
          calculo.entrada > 0 && calculo.valorParcela > 0
            ? 0
            : calculo.parcelas,
        entrada: toNumber(value.floatValue) || 0,
        saldo: calculo.divida - value.floatValue,
      });
    } else {
      setCalculo({
        ...calculo,
        parcelas: 1,
        entrada: toNumber(value.floatValue) || 0,
        saldo: calculo.divida - value.floatValue,
        valorParcela: calculo.divida - value.floatValue,
      });
    }
  };

  const handleChangeValorParcela = value => {
    value.floatValue = value.floatValue || 0;
    setCalculo({
      ...calculo,
      parcelas:
        calculo.valorParcela > 0 && calculo.entrada > 0 ? 0 : calculo.parcelas,
      valorParcela: toNumber(value.floatValue) || 0,
    });
  };

  const handleSlideParcelas = value => {
    setCalculo({
      ...calculo,
      valorParcela:
        calculo.parcelas > 0 && calculo.entrada > 0
          ? 0
          : toNumber(calculo.valorParcela),
      parcelas: value,
    });
  };

  const handleChangeParcelas = event => {
    let qty = parseInt(event.target.value, 10) || 0;
    if (qty > 120) qty = 120;
    setCalculo({
      ...calculo,
      valorParcela:
        calculo.parcelas > 0 && calculo.entrada > 0
          ? 0
          : toNumber(calculo.valorParcela),
      parcelas: qty,
    });
  };

  const handleChangeParcelaEntrada = value => {
    const juros =
      parseInt(value, 10) === 1
        ? valoresDefault.juros
        : valoresDefault.jurosParcelado;
    setCalculo({ ...calculo, parcelaEntrada: value, juros });
  };

  const validaValores = () => {
    if (calculo.entrada < valoresDefault.entrada && calculo.entrada !== 0) {
      setCurrent(1);
      message.error(
        `O valor da ENTRADA não pode ser menor que R$ ${valoresDefault.entrada}`,
        10
      );
      setCalculo({
        ...calculo,
        entrada: valoresDefault.entrada,
      });
      return false;
    }

    if (calculo.entrada === 0 && calculo.parcelas === 0) {
      setCurrent(2);
      message.error(`Favor especificar a quantidade de parcelas desejada.`);
      return false;
    }

    // if (calculo.entrada >= valoresDefault.entradaMaxima) {
    //   setCurrent(1);
    //   message.error(
    //     `Para entradas a partir de R$ ${valoresDefault.entradaMaxima} entre em contato com o vendedor`,
    //     10
    //   );
    //   return false;
    // }

    if (
      calculo.valorParcela < valoresDefault.valorParcela &&
      calculo.valorParcela !== 0 &&
      calculo.parcelas > 1
    ) {
      setCurrent(3);
      message.error(
        `O valor da PARCELA não pode ser menor que R$ ${valoresDefault.valorParcela}`
      );
      setCalculo({
        ...calculo,
        valorParcela: valoresDefault.valorParcela,
      });
      return false;
    }

    if (
      calculo.valorParcela === 0 &&
      (calculo.entrada === 0 || calculo.parcelas === 0)
    ) {
      setCurrent(3);
      message.error(
        `O valor da PARCELA não pode ser menor que R$ ${valoresDefault.valorParcela}`
      );
      setCalculo({
        ...calculo,
        valorParcela: valoresDefault.valorParcela,
      });
      return false;
    }

    if (calculo.valorParcela > valoresDefault.saldo) {
      setCurrent(3);
      message.error(`O valor da PARCELA é inválido`);
      return false;
    }
    return true;
  };

  const round = valor => {
    return Math.round(valor);
  };

  /*
   * Calcula o Valor Presente Líquido para
   * uma variação de período constante
   *
   * @taxa => taxa de desconto
   * @montantes => vetor com os valores com os recebimentos ou pagamentos
   *
   */
  const valorPresente = (taxa, montantes, saldo = valoresDefault.saldo) => {
    let vpl = 0;
    let i = 0;
    let sair = false;
    while (saldo - vpl > 0 && i < montantes.length && !sair) {
      if (montantes[i] / Math.pow(1.0 + taxa, i + 1) + vpl > saldo) {
        sair = true;
      } else {
        vpl += montantes[i] / Math.pow(1.0 + taxa, i + 1);
        i++;
      }
    }
    return { valor: round(vpl), parcelas: i };
  };

  /*
   * Valor Futuro PRICE
   */
  function financiamentoPrice(financiamento, juroMensal, meses) {
    juroMensal /= 100;
    const parcela =
      (financiamento * juroMensal) / (1 - 1 / (1 + juroMensal) ** meses);
    return parcela;
  }

  const calcularEntrada = calc => {
    calc.montante = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < calc.parcelas; i++) {
      calc.montante.push(calc.valorParcela);
    }
    calc.vpl = valorPresente(calc.juros / 100, calc.montante);
    calc.entrada = round(calc.saldo - calc.vpl.valor + valoresDefault.entrada);
    calc.parcelas = calc.vpl.parcelas;
    setCalculo(calc);
  };

  const calcularParcelas = calc => {
    calc.montante = [];
    for (let i = 0; i < valoresDefault.parcelas; i++) {
      calc.montante.push(calc.valorParcela);
    }
    calc.vpl = valorPresente(
      calc.juros / 100,
      calc.montante,
      calc.divida - calc.entrada
    );
    calc.entradaNova =
      calc.divida - calc.entrada - calc.vpl.valor + calc.entrada;
    calc.parcelas = calc.vpl.parcelas;
    if (calc.entradaNova !== calc.entrada) {
      calc.parcelas++;
    }
    setCalculo(calc);
  };

  const calcula = () => {
    const calc = calculo;
    if (calc.entrada && calc.parcelas) {
      if (calc.entrada >= calc.divida) {
        calc.parcelas = 0;
      }
    }
    if (calc.entrada === 0) {
      calcularEntrada(calc);
    } else if (calc.parcelas === 0 && calc.entrada < calc.divida) {
      calcularParcelas(calc);
      if (calc.parcelas > valoresDefault.parcelas) {
        calc.parcelas = tipo === 'T' ? 120 : 180;
        calc.valorParcela = 0;
        setCalculo(calc);
        calcula(calc);
      }
    } else if (
      calc.valorParcela === 0 ||
      calc.valorParcela < valoresDefault.valorParcela
    ) {
      const valorParc =
        round(
          financiamentoPrice(
            calc.divida - calc.entrada,
            calc.juros,
            calc.parcelas
          ) * 100
        ) / 100;

      calc.valorParcela = valorParc; // .toFixed(2).replace('.', ',');

      // if (valorParc < valoresDefault.valorParcela) {
      //   if ((calc.saldo <= valoresDefault.valorParcela)) {
      //     calc.parcelas = 1;
      //   }else {
      //     calc.parcelas = 0;
      //     calc.valorParcela = valoresDefault.valorParcela;
      //   }
      //   calcularParcelas(calc);
      // }
    }
    setCalculo(calc);
    const id = getIdentification();
    try {
      api.post('/mail.php', { id, calc });
    } catch (e) {
      console.log(e);
    }
  };

  const steps = [
    {
      title: 'Identificação',
      content: null,
    },
    {
      title: 'Entrada',
      content: null,
    },
    {
      title: 'Parcelas',
      content: null,
    },
    {
      title: 'Valor Parcela',
      content: null,
    },
    {
      title: 'Resultado',
      content: null,
    },
  ];

  const hanbleCheckAindaNaoSei = e => {
    if (e.target.checked) {
      setCalculo({ ...calculo, entrada: 0 });
    }
  };

  const hanbleCheckAindaNaoSeiParcelas = e => {
    if (e.target.checked) {
      setCalculo({ ...calculo, parcelas: 0 });
    }
  };

  const renderPasso = step => {
    switch (step) {
      case 0:
        // Identificacao
        if (!steps[current].content) {
          steps[current].content = (
            <Form
              schema={schema}
              onSubmit={handleSubmit}
              initialData={sessionData}
            >
              <div>
                <Row justify="start">
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 12, offset: 6 }}
                    className="ant-form-item-control"
                  >
                    {/* <Title level={1} style={{ color: '#626262' }}>
                      Identificação
                    </Title> */}
                    <label htmlFor="nomeCompleto">Nome Completo</label>
                    <Input
                      id="nomeCompleto"
                      name="nomeCompleto"
                      placeholder="Digite seu nome completo"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 12, offset: 6 }}
                    className="ant-form-item-control"
                  >
                    <label htmlFor="email">Email</label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Digite seu email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 12, offset: 6 }}
                    className="ant-form-item-control"
                  >
                    <label htmlFor="celular">Nº Celular</label>
                    <Input
                      id="celular"
                      name="celular"
                      placeholder="Digite o número do seu celular com DDD"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 12, offset: 6 }}
                    className="ant-form-item-control"
                  >
                    <label htmlFor="corretor">Nome do Corretor</label>
                    <Input
                      id="corretor"
                      name="corretor"
                      placeholder="Digite o nome do corretor se houver"
                    />
                  </Col>
                </Row>
                <button
                  id="btnSubmit"
                  type="submit"
                  label="submit"
                  style={{ display: 'none' }}
                />
              </div>
            </Form>
          );
        }
        break;
      case 1:
        // Entrada
        if (!steps[current].content) {
          steps[current].content = (
            <div>
              <Row justify="start">
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 16, offset: 4 }}
                  className="ant-form-item-control"
                >
                  <label htmlFor="entrada">
                    <Title level={1} style={{ color: '#626262' }}>
                      Quanto você quer dar de entrada ?
                    </Title>
                  </label>
                  <Title level={4} style={{ color: '#1890ff' }}>
                    * Valor mínimo de entrada&nbsp;
                    <CurrencyFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="R$ "
                      displayType="text"
                      style={{ color: '#1890ff' }}
                      value={valoresDefault.entrada}
                    />
                    &nbsp;parcelado em até <u> 4x sem juros.</u>
                  </Title>
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 10, offset: 4 }}
                  className="ant-form-item-control"
                >
                  <CurrencyFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    className="calculo"
                    id="entrada"
                    name="entrada"
                    value={calculo.entrada}
                    onValueChange={handleChangeEntrada}
                    placeholder=""
                  />
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 6 }}
                  className="ant-form-item-control"
                >
                  <Select
                    size="large"
                    value={calculo.parcelaEntrada}
                    disabled={calculo.entrada >= calculo.divida}
                    onChange={handleChangeParcelaEntrada}
                    style={{ height: 49 }}
                  >
                    <Option value={1} key={1}>
                      à Vista
                    </Option>
                    <Option value={2} key={2}>
                      em 2 vezes
                    </Option>
                    <Option value={3} key={3}>
                      em 3 vezes
                    </Option>
                    <Option value={4} key={4}>
                      em 4 vezes
                    </Option>
                    {/* <Option value={5} key={5}>
                      em 5 vezes
                    </Option> */}
                  </Select>
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 16, offset: 4 }}
                  className="ant-form-item-control"
                >
                  <Checkbox onChange={hanbleCheckAindaNaoSei}>
                    Não sei, calcule pra mim
                  </Checkbox>
                </Col>
              </Row>
            </div>
          );
        }
        break;
      case 2:
        // Qtde Parcelas
        if (!steps[current].content) {
          steps[current].content = (
            <div>
              <Row justify="start">
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 16, offset: 4 }}
                  className="ant-form-item-control"
                >
                  <label htmlFor="parcelas">
                    <Title level={1} style={{ color: '#626262' }}>
                      Em quantas parcelas quer pagar ?
                    </Title>
                  </label>
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 8, offset: 6 }}
                  className="ant-form-item-control"
                >
                  <Slider
                    min={0}
                    max={valoresDefault.parcelas}
                    onChange={handleSlideParcelas}
                    value={
                      typeof calculo.parcelas === 'number'
                        ? calculo.parcelas
                        : 0
                    }
                  />
                </Col>
                <Col xs={{ span: 22, offset: 1 }} lg={{ span: 3 }}>
                  <input
                    min={0}
                    max={valoresDefault.parcelas}
                    className="calculo"
                    type="number"
                    value={calculo.parcelas}
                    onChange={handleChangeParcelas}
                  />
                </Col>
                {calculo.entrada > 0 && (
                  <Col
                    className="ant-form-item-control"
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 8, offset: 6 }}
                  >
                    <Checkbox onChange={hanbleCheckAindaNaoSeiParcelas}>
                      Não sei, calcule pra mim
                    </Checkbox>
                  </Col>
                )}
              </Row>
            </div>
          );
        }
        break;
      case 3:
        // Valor da parcela
        if (!steps[current].content) {
          steps[current].content = (
            <div>
              <Row justify="start">
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 16, offset: 4 }}
                  className="ant-form-item-control"
                >
                  <label htmlFor="valorParcela">
                    <Title level={1} style={{ color: '#626262' }}>
                      Quanto você quer pagar por mês ?
                    </Title>
                  </label>
                  {/* <Title level={4} style={{ color: '#1890ff' }}>
                    * Valor mínimo da parcela{' '}
                    <CurrencyFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="R$ "
                      displayType="text"
                      style={{ color: '#1890ff', fontWeight: 'bold' }}
                      value={valoresDefault.valorParcela}
                    />
                  </Title> */}
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 16, offset: 4 }}
                  className="ant-form-item-control"
                >
                  <CurrencyFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    className="calculo"
                    id="valorParcela"
                    name="valorParcela"
                    value={calculo.valorParcela}
                    onValueChange={handleChangeValorParcela}
                    placeholder="Digite o valor da parcela"
                  />
                </Col>
              </Row>
            </div>
          );
        }
        break;
      case 4:
        steps[current].content = (
          <>
            <Result
              icon={<SmileOutlined theme="twoTone" />}
              title="Parabéns, Você já pode garantir seu imóvel!"
            >
              <Title level={4} className="text-center">
                <i>
                  As Datas de vencimento iniciam em 1 dia após assinatura do
                  contrato.
                </i>
              </Title>
            </Result>

            <Row
              justify="center"
              style={{ textAlign: 'center', color: 'green' }}
            >
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 7, offset: 2 }}>
                <label>
                  <Title level={4}>Entrada</Title>
                </label>
                <Title level={1} style={{ color: 'green' }}>
                  <CurrencyFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    displayType="text"
                    value={calculo.entrada}
                  />
                </Title>
                <Title level={3} style={{ color: '#1890ff' }}>
                  {calculo.parcelaEntrada > 1
                    ? `Parcelada em ${calculo.parcelaEntrada} Vezes`
                    : 'à Vista'}
                </Title>
              </Col>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 5 }}>
                <label>
                  <Title level={4}>Parcelas</Title>
                </label>
                <Title level={1} style={{ color: 'green' }}>
                  <CurrencyFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix=""
                    displayType="text"
                    value={calculo.parcelas}
                  />
                </Title>

                <Title level={3} style={{ color: '#1890ff' }}>
                  Meses
                </Title>
              </Col>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 6 }}>
                <label>
                  <Title level={4}>Valor Parcelas</Title>
                </label>
                <Title level={1} style={{ color: 'green' }}>
                  <CurrencyFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    displayType="text"
                    value={calculo.valorParcela}
                  />
                </Title>
                <Title level={3} style={{ color: '#1890ff' }}>
                  Fixas
                </Title>
              </Col>
            </Row>
            <br />
            <Row
              justify="center"
              style={{ textAlign: 'center', color: 'green' }}
            >
              <Col xs={{ span: 24 }}>
                <i className="center">
                  * O proprietário se reserva ao direito legal de retificação de
                  dados, em caso de eventual inconsistência sistêmica no
                  simulador, a fim de preservar o planejamento correto do
                  financiamento para o comprador.
                </i>
              </Col>
            </Row>
          </>
        );
        break;
      default:
        break;
    }
    return steps[current].content;
  };

  const next = () => {
    if (current === 0) {
      document.getElementById('btnSubmit').click();
    } else if (current === 1) {
      if (calculo.entrada >= calculo.divida) {
        setCalculo(old => ({
          ...old,
          parcelas: 0,
          entrada: toNumber(old.divida) || 0,
          valorParcela: 0,
          parcelaEntrada: 1,
        }));
        setCurrent(4);
      } else if (calculo.saldo <= valoresDefault.valorParcela) {
        setCalculo(old => ({
          ...old,
          parcelas: 1,
          valorParcela: calculo.saldo,
        }));
        calcula();

        setCurrent(4);
      } else {
        setCurrent(current + 1);
      }
    } else if (
      current === 2 &&
      calculo.entrada !== 0 &&
      calculo.parcelas !== 0
    ) {
      if (calculo.divida - calculo.entrada <= valoresDefault.valorParcela) {
        setCalculo({ ...calculo, parcela: 1 });
      } else {
        setCalculo({ ...calculo, valorParcela: 0 });
      }
      setCurrent(current + 2);
      if (validaValores()) calcula();
    } else if (current === 3) {
      if (validaValores()) {
        calcula();
        setCurrent(current + 1);
      }
    } else if (validaValores()) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current === 0) {
      setTipo(null);
      setLote(null);
    } else if (
      (calculo.entrada >= calculo.divida && current !== 1) ||
      (calculo.saldo <= valoresDefault.valorParcela && current !== 1)
    ) {
      setCurrent(1);
    } else {
      setCurrent(value => value - 1);
    }
  };

  const renderSteps = () => {
    return (
      <Row>
        <Col xs={{ span: 24 }}>
          <Steps current={current}>
            {steps.map(item => (
              <Step
                key={item.title}
                title={item.title}
                status={item.status || ''}
              />
            ))}
          </Steps>
          <div className="steps-content">
            {steps[current].content || renderPasso(current)}
          </div>
          <div className="steps-action text-center">
            <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
              Voltar
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              disabled={current === steps.length - 1}
              type="primary"
              onClick={() => next()}
            >
              Próximo
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div id="fh5co-popular-properties" className="fh5co-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12  text-center heading-section ">
              <h3>
                Simulando Valores para
                {tipo === 'C' ? ' Casa de 88 m² em terreno ' : ' Terreno '}
                <b>de {String(lote.m2).replace('.', ',')} m²</b>
              </h3>
              <h4>
                ESTÃO INCLUSOS NO FINANCIAMENTO TODAS AS DESPESAS DE ESCRITURA E
                REGISTRO DE MATRÍCULA{' '}
                <span aria-label="Sorriso Psicando" role="img">
                  😉
                </span>
              </h4>
            </div>
          </div>
          <div className="row">
            <Content>
              <div>{renderSteps()}</div>
            </Content>
          </div>
        </div>
      </div>
    </>
  );
}

Simulador.propTypes = {
  lote: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tipo: PropTypes.string.isRequired,
  setTipo: PropTypes.func.isRequired,
  setLote: PropTypes.func.isRequired,
};
