import React, { useLayoutEffect, useState } from 'react';

import 'react-animated-slider/build/horizontal.css';
import './styles.css';

import MapaImagem from '../../assets/images/mapa.png';
import Simulador from '../Simulador';

const lotes = [
  {
    id: 'lote_2d_1',
    coords: '1716,407,1822,448,1722,720,1611,678,1669,531',
    m2: '250.0',
    price: 1000,
    free: false,
  },
  {
    id: 'lote_2d_2',
    coords: '1825,448,1933,489,1830,758,1722,720',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_2d_3',
    coords: '1930,488,2044,530,1941,804,1830,763',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_4',
    coords: '2049,532,2157,577,2049,849,1944,807',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_5',
    coords: '2157,574,2266,616,2163,890,2052,849',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_6',
    coords: '2268,618,2379,657,2274,935,2163,894',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_7',
    coords: '2379,660,2487,702,2382,973,2274,935',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_8',
    coords: '2490,702,2598,746,2493,1021,2385,976',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_9',
    coords: '2601,746,2709,788,2604,1062,2496,1021',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2d_10',
    coords: '2781,1126,2607,1062,2712,788,2826,832',
    m2: '335.26',
    price: 1000,
  },
  {
    id: 'lote_1d_1',
    coords: '1550,774,1658,815,1553,1090,1445,1048',
    m2: '250.0',
    price: 1000,
  },
  {
    id: 'lote_1d_2',
    coords: '1661,813,1769,857,1664,1129,1556,1090',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1d_3',
    coords: '1769,854,1877,898,1775,1173,1664,1131',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_1d_4',
    coords: '1880,897,1988,941,1883,1216,1775,1169',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_1d_5',
    coords: '1991,940,2099,982,1994,1256,1886,1215',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_1d_6',
    coords: '2099,982,2210,1026,2105,1298,1997,1256',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_1d_7',
    coords: '2210,1026,2321,1068,2213,1339,2105,1301',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_1d_8',
    coords: '2321,1068,2429,1109,2327,1384,2216,1342',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1d_9',
    coords: '2432,1109,2543,1151,2435,1425,2327,1387',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_1d_10',
    coords: '2543,1151,2651,1192,2546,1467,2435,1428',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1d_11',
    coords: '2651,1192,2762,1237,2704,1531,2546,1470',
    m2: '305.75',
    price: 1000,
  },
  {
    id: 'lote_2c_1',
    coords: '1500,1068,1608,1109,1503,1384,1395,1345',
    m2: '250.0',
    price: 1000,
  },
  {
    id: 'lote_2c_2',
    coords: '1611,1109,1716,1154,1614,1428,1503,1387',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_3',
    coords: '1719,1154,1827,1195,1725,1470,1614,1428',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_4',
    coords: '1830,1195,1938,1237,1833,1511,1725,1470',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_5',
    coords: '1941,1237,2049,1281,1944,1553,1836,1511',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_6',
    coords: '2052,1278,2160,1323,2055,1595,1947,1553',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_7',
    coords: '2163,1321,2271,1363,2166,1640,2055,1596',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_2c_8',
    coords: '2271,1363,2382,1407,2277,1682,2166,1640',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_9',
    coords: '2385,1406,2490,1448,2388,1722,2277,1681',
    m2: '250.0',
    price: 880,
    free: false,
  },
  {
    id: 'lote_2c_10',
    coords: '2493,1448,2601,1489,2496,1764,2388,1722',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2c_11',
    coords: '2601,1489,2701,1528,2615,1811,2498,1764',
    m2: '250.51',
    price: 1000,
  },
  {
    id: 'lote_1c_1',
    coords: '1262,1409,1370,1448,1264,1725,1156,1683',
    m2: '250.0',
    price: 1000,
  },
  {
    id: 'lote_1c_2',
    coords: '1373,1450,1481,1492,1375,1766,1267,1725',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_3',
    coords: '1484,1492,1592,1536,1486,1811,1378,1766',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_4',
    coords: '1594,1534,1703,1575,1597,1852,1489,1808',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_5',
    coords: '1703,1575,1814,1617,1705,1894,1600,1850',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_6',
    coords: '1814,1618,1924,1657,1816,1937,1708,1895',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_7',
    coords: '1924,1661,2033,1703,1927,1977,1819,1936',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_8',
    coords: '2033,1703,2144,1744,2038,2019,1930,1977',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_9',
    coords: '2144,1744,2254,1786,2149,2063,2038,2019',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_10',
    coords: '2254,1786,2365,1827,2260,2105,2149,2060',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_11',
    coords: '2365,1830,2473,1869,2371,2146,2260,2105',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_1c_12',
    coords: '2476,1872,2584,1913,2510,2202,2371,2146',
    m2: '284.18',
    price: 1000,
  },
  {
    id: 'lote_2b_1',
    coords: '1201,1700,1312,1744,1206,2019,1095,1974',
    m2: '250.0',
    price: 1000,
  },
  {
    id: 'lote_2b_2',
    coords: '1312,1744,1420,1786,1317,2060,1206,2019',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_3',
    coords: '1423,1784,1531,1826,1425,2103,1317,2059',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_4',
    coords: '1533,1826,1642,1868,1536,2145,1428,2101',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_5',
    coords: '1642,1870,1753,1912,1647,2187,1539,2145',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_6',
    coords: '1755,1911,1863,1952,1758,2227,1650,2188',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_7',
    coords: '1863,1952,1972,1997,1869,2271,1758,2230',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_8',
    coords: '1974,1997,2082,2038,1977,2313,1869,2271',
    m2: '250.0',
    price: 880,
  },
  {
    id: 'lote_2b_9',
    coords: '2085,2038,2193,2083,2088,2354,1980,2313',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_2b_10',
    coords: '2196,2080,2307,2119,2199,2399,2091,2354',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_2b_11',
    coords: '2307,2121,2415,2160,2310,2438,2202,2399',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_2b_12',
    coords: '2415,2169,2507,2202,2465,2357,2437,2490,2313,2438',
    m2: '250.0',
    free: false,
    price: 1000,
  },
  {
    id: 'lote_1b_1',
    coords: '1411,2213,1522,2255,1417,2529,1309,2487',
    m2: '250.0',
    free: false,
    price: 1000,
  },
  {
    id: 'lote_1b_2',
    coords: '1522,2255,1633,2296,1528,2571,1420,2529',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_1b_3',
    coords: '1633,2296,1741,2338,1639,2612,1528,2571',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_1b_4',
    coords: '1744,2338,1852,2379,1750,2654,1639,2615',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_1b_5',
    coords: '1855,2379,1963,2421,1858,2698,1750,2657',
    m2: '250.0',
    free: false,
    price: 880,
  },
  {
    id: 'lote_1b_6',
    coords: '1963,2421,2074,2463,1969,2740,1861,2698',
    m2: '250.0',
    free: false,
    price: 880,
  },
];

export default function Mapa() {
  const [tipo, setTipo] = useState();
  const [lote, setLote] = useState();

  const handleLoteClick = value => {
    setTipo('T');
    setLote(value);
  };

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';
    script.async = true;
    document.body.appendChild(script);

    const terrenos = document.createElement('script');
    terrenos.src = '/assets/js/terrenos.js';
    terrenos.async = true;
    document.body.appendChild(terrenos);
  }, [tipo]);

  return lote && tipo ? (
    <Simulador lote={lote} tipo={tipo} setTipo={setTipo} setLote={setLote} />
  ) : (
    <>
      <div id="fh5co-popular-properties" className="fh5co-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center heading-section animate-box">
              <h3>
                Escolha {tipo === 'C' ? 'o Terreno para sua Casa' : 'o Terreno'}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 animate-box zoom">
              <img
                className="image-map"
                src={MapaImagem}
                width="100%"
                useMap="#image-map"
                alt="Mapa dos terrenos"
              />

              <map name="image-map">
                {lotes
                  .filter(ls => ls.free !== false)
                  .map(l => (
                    <area
                      key={l.id}
                      alt={l.id}
                      title={l.id}
                      href="#"
                      onClick={() => handleLoteClick(l)}
                      coords={l.coords}
                      shape="poly"
                    />
                  ))}
              </map>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
