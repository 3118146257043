import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../assets/logo.png';

export default function LayoutWrapper({ children }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // Ajuste o valor conforme necessário
    };

    handleResize(); // Verifica no carregamento inicial

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="fh5co-wrapper">
      <div id="fh5co-page">
        <header id="fh5co-header-section" className="sticky-banner">
          <div className="container">
            <div className="nav-header1">
              <div className="row">
                <div
                  className="col-xs-12 col-md-4 text-center"
                  style={{ paddingTop: 30 }}
                >
                  {isMobile ? (
                    <h2 style={{ fontFamily: 'Oxygen', fontStyle: 'italic' }}>
                      Sonho de morar Bem e com Qualidade
                    </h2>
                  ) : (
                    <h1 style={{ fontFamily: 'Oxygen', fontStyle: 'italic' }}>
                      Sonho de morar Bem e com Qualidade
                    </h1>
                  )}
                </div>
                <div className="col-xs-12 col-md-4 text-center">
                  {/* <a
                    href="/"
                    className="js-fh5co-nav-toggle fh5co-nav-toggle dark text-center"
                  ></a> */}
                  <a href="/">
                    <img src={Logo} height={isMobile ? 80 : 120} alt="" />
                  </a>
                </div>
                <div
                  className="col-xs-12 col-md-4 text-center"
                  style={{ paddingTop: 30 }}
                >
                  {isMobile ? (
                    <>
                      <h3 style={{ fontFamily: 'Roboto' }}>(11) 99623-3015</h3>
                      <h4 style={{ fontFamily: 'Roboto' }}>
                        Direto com o Proprietário
                      </h4>
                    </>
                  ) : (
                    <>
                      <h2 style={{ fontFamily: 'Roboto' }}>(11) 99623-3015</h2>
                      <h3 style={{ fontFamily: 'Roboto' }}>
                        Direto com o Proprietário
                      </h3>
                    </>
                  )}
                </div>
              </div>

              {/* <nav id="fh5co-menu-wrap" role="navigation">
                <ul className="sf-menu" id="fh5co-primary-menu">
                  <li className="active">
                    <a href="/">Home</a>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
        </header>
        {children}
        <footer>
          <div id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>
                    Copyright {new Date().getFullYear()}{' '}
                    <b>boavistapiracaia.com.br</b> - Todos os direitos
                    reservados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

LayoutWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
