import React, { useEffect, useState } from 'react';
// import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './slider.css';

import { PictureFilled } from '@ant-design/icons';
import Ruas from '../../assets/images/foto-bg-bvp.jpeg';

const renderHome = isMobile => {
  return (
    <>
      <aside id="fh5co-hero" className="js-fullheight">
        <div className="flexslider js-fullheight">
          <ul className="slides">
            <li style={{ backgroundImage: `url(${Ruas})` }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xs-12  col-md-3 js-fullheight slider-text">
                    <div className="slider-text-inner">
                      <div className={isMobile ? 'desc-nobg' : 'desc'}>
                        <h2
                          className="price"
                          style={{ color: isMobile ? 'white' : '' }}
                        >
                          Oportunidade Única
                        </h2>
                        <h1 hidden={isMobile}>
                          <PictureFilled /> Terrenos
                        </h1>
                        {/* <h1>
                          <HomeFilled /> Casas
                        </h1> */}
                        <br />
                        <p style={{ textAlign: 'center' }} className="">
                          <a
                            style={{ fontSize: '2.5rem', fontWeight: 800 }}
                            className="btn btn-primary btn-lg"
                            href="/mapa"
                          >
                            Simular <br />
                            Financiamento
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="col-xs-12 text-center col-md-12">
            <h4
              style={{
                fontWeight: 'bold',
                color: 'white',
                paddingTop: 10,
              }}
            >
              WW Empreendimentos Imobiliários
            </h4>
          </div>
        </div>
      </aside>
    </>
  );
};

export default function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 640); // Ajuste o valor conforme necessário
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '/assets/js/main.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);
  return renderHome(isMobile);
}
